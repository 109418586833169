import "./success.css";

const Success = () => {
  return (
    <div className="success">
      <h2 className="success-title">Form Submitted Successfully</h2>
    </div>
  );
};
export default Success;
